import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";


const Who = () => (
    <div className={"who"}>
        <div className="container">

        </div>
    </div>


);

export default Who

