import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";

var haxoYears = new Date().getFullYear() - 2015;

const Welcome = () => (
    <div className={"welcome"}>
        <div className="overlay-outer">
            <div className="overlay-inner d-flex align-items-center">
                <div className="container text-center">
                    <div>
                        <h1 className={""}>
                            Vývoj webových aplikací
                            <span>Jan Harsa & Petr Balvín</span>
                        </h1>

                        <p className={"lead mt-5"}>
                            Vytváříme aplikace na zakázku, které usnadňují vaše podnikání.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Welcome

