import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import {faDatabase, faSpinner, faCheckCircle, faCopy, faUserFriends} from "@fortawesome/free-solid-svg-icons";

const Services = () => (
    <div className={"services section"}>
        <div className="container">

            <h2 className={"text-center"}>
                Co děláme
                <span>Náš denní chléb</span>
            </h2>
            {/*
            <p className="lead mt-4 text-center">
                Díky přesahu našich znalostí do jiných oborů dokážeme navrhnout a naprogramovat velmi dobrá řešení,<br/>
                Umíme se vcítit do uživatelů, kteří aplikaci budou používat.
            </p>
            */}

            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <div className="card border-0">
                        <div className="card-body text-center">
                            <span className="material-icons-outlined text-primary mb-3"><FontAwesomeIcon icon={faDatabase}/></span>
                            <h3 className={"mb-3"}>Programování</h3>
                            <p>Volíme ověřené moderní technologie pro zajištění <strong>dlouhodobé udržitelnosti</strong> fungování vašeho projektu.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0">
                        <div className="card-body text-center">
                            <span className="material-icons-outlined text-primary mb-3"><FontAwesomeIcon icon={faCopy}/></span>
                            <h3 className={"mb-3"}>Tvorba webových stránek</h3>
                            <p>Šijeme weby přesně na míru vašemu podnikání. <strong>Naše weby sedí jako ulité</strong> ať jste živnostník, malá firma nebo velká korporace.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0">
                        <div className="card-body text-center">
                            <span className="material-icons-outlined text-primary mb-3"><FontAwesomeIcon icon={faCheckCircle}/></span>
                            <h3 className={"mb-3"}>Optimalizace</h3>
                            <p>S našimi klienty spolupracujeme dlouhodobě a stále projekty ladíme pro <strong>dosažení nejlepších výsledků</strong>.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0">
                        <div className="card-body text-center">
                            <span className="material-icons-outlined text-primary mb-3"><FontAwesomeIcon icon={faUserFriends}/></span>
                            <h3 className={"mb-3"}>Poradenství a konzultace</h3>
                            <p>Rádi se s vámi podělíme o <strong>více jak 10 let zkušeností</strong> v našem oboru. Pomůžeme vám vymyslet optimální řešení.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

);

export default Services

