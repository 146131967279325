import React from "react"
import Layout from "../components/Layout/Layout"
import Welcome from "../components/Sections/Welcome";
import Services from "../components/Sections/Services";
import Who from "../components/Sections/Who";
import How from "../components/Sections/How";
import Pricing from "../components/Sections/Pricing";

export default function Home() {
    return (
        <Layout>
            <Welcome/>
            <Who/>
            <Services/>
            {/*<How/>*/}
            {/*<Pricing/>*/}
        </Layout>
    );
}
